<template>
  <div :class="['default-layout', hydrated ? 'hydrated' : 'hydrating']">
    <slot />
  </div>
</template>
<script setup lang="ts">

const hydrated = ref(false)

onMounted(() => {
  hydrated.value = true
})

</script>
